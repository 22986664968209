<template>
  <div>
    <page-title :heading="$t('accounting.lang_itemMargin')" :icon="icon"
                :subheading="$t('accounting.lang_itemMargin')"></page-title>
    <div class="app-main__inner">
      <ItemsMarginsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import ItemsMarginsComponent from "@/components/analytics/warewanlytics/margins/ItemsMarginsComponent";

export default {
  components: {
    ItemsMarginsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>